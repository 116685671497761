<template>
  <div class="layout-wrapper">
    <Navbar></Navbar>
    <div class="content row no-gutters">
      <Sidebar class="col-lg-3"></Sidebar>

      <div class="col-lg-9" style="background: #f6f7fb;">
        <!-- <div class="content-container shadow p-3 mb-3 bg-white rounded"> -->
        <router-view></router-view>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navigation/Navbar";
import auth from "@/firebase/firebaseInit";
// import C from "@/constants";
import Sidebar from "@/components/navigation/SidebarAdmin";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "User",
  components: {
    Sidebar: Sidebar,
    Navbar: Navbar,
  },
  data() {
    return {};
  },
  methods: {
    loadFirebaseUser() {
      if (!auth.currentUser) {
        // this.isLoggedIn = false;
      } else {
        //get user id from firebase auth
        this.userId = auth.currentUser.uid;
        // this.isLoggedIn = true;
        console.log('USERID: '+this.userId);
        //get user document from firestore
        var docRef = db.collection("users").doc(this.userId);
        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("firebase GET userDoc SUCCESS");
              // save userDoc in store
              this.$store.commit("setUser", doc.data());
            } else {
              // doc.data() will be undefined in this case
              console.log("UserDoc not found!");
            }
          })
          .catch((error) => {
            console.log("firebase GET userDoc ERROR: ", error);
          });
      }
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  created() {
    this.loadFirebaseUser();

    //set dashboard mode to true
    this.$store.commit("setIsDash", true);
  },
};
</script>

<style lang="scss" scoped>
// @import "../assets/css/talkii.css";
// @import "../assets/css/dialog.css";
// @import "../assets/css/switch.scss";

@import "../assets/css/navbar.scss";
@import "../assets/css/talkii.css";
@import "../assets/css/main.css";
@import "../assets/css/platform.css";
@import "../assets/css/switch.css";
@import "../assets/css/dialog-dark.css";
@import "../assets/css/dialog.css";
@import "../assets/css/duke.css";
</style>
