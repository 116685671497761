<template>
  <div class="sidebar" style="background: #f6f7fb;">
    <div class="sidebar-top" >
      <!-- <div class="user-icon">{{ userLabel }}</div>
      <div class="text-center">
        <h4>{{ userDoc.name }}</h4>
        <p>{{ userDoc.email }}</p>
      </div> -->
      <div class="user-icon"><i class="fal fa-cog fa-2x"></i></div>
      <p>DUKE</p>
      <p><small>ADMIN</small></p>
    </div>
    <div class="sidebar-nav">
      <router-link :to="{ name: 'UsersList' }">
        <div @click="changeActive(0)" v-bind:class="{ active: menus[0] }" class="jbtn-nav">
          <i class="fad fa-users"></i><span class="ml-3">Users List</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'VerifyPress' }">
        <div @click="changeActive(1)" v-bind:class="{ active: menus[1] }" class="jbtn-nav">
          <i class="fad fa-clipboard-check"></i><span class="ml-3">Verify Press</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'CreateUser' }">
        <div @click="changeActive(2)" v-bind:class="{ active: menus[2] }" class="jbtn-nav">
          <i class="fa fa-plus"></i><span class="ml-3">Create User</span>
        </div>
      </router-link>
      <hr />
      <router-link :to="{ name: 'WatchCatalog' }">
        <div @click="changeActive(3)" v-bind:class="{ active: menus[3] }" class="jbtn-nav">
          <i class="fad fa-watch"></i><span class="ml-3">Watch Catalog</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'WatchesList' }">
        <div @click="changeActive(4)" v-bind:class="{ active: menus[4] }" class="jbtn-nav">
          <i class="fad fa-watch"></i><span class="ml-3">Watches</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'CreateWatch' }">
        <div @click="changeActive(5)" v-bind:class="{ active: menus[5] }" class="jbtn-nav">
          <i class="fa fa-plus"></i><span class="ml-3">Add Watch</span>
        </div>
      </router-link>
      <hr />
      <router-link :to="{ name: 'AppointmentsAdmin' }">
        <div @click="changeActive(6)" v-bind:class="{ active: menus[6] }" class="jbtn-nav">
          <i class="fad fa-calendar-check"></i><span class="ml-3">Appointments</span>
        </div>
      </router-link>

      <router-link :to="{ name: 'ServiceCatalogue' }">
        <div @click="changeActive(7)" v-bind:class="{ active: menus[7] }" class="jbtn-nav">
          <i class="fad fa-tools"></i><span class="ml-3">Service Catalog</span>
        </div>
      </router-link>
      <hr />
      <router-link :to="{ name: 'News' }">
        <div @click="changeActive(8)" v-bind:class="{ active: menus[8] }" class="jbtn-nav">
          <i class="fad fa-newspaper"></i><span class="ml-3">News</span>
        </div>
      </router-link>

      <!-- <router-link :to="{ name: 'Notifications' }">
        <div @click="changeActive(9)" v-bind:class="{ active: menus[9] }" class="jbtn-nav">
          <i class="fad fa-bell"></i
          ><span class="ml-3"
            >Notifications <span class="badge badge-pill badge-primary">{{ newNotifications }}</span></span
          >
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import C from "@/const";
import firebaseApp from "@/firebase/firebaseInit";
const db = firebaseApp.db;


// I'd try cleaning your yarn/npm cache and rebuilding.
// Additionally, ensure that you don't have multiple instances of @firebase/app in your dependency tree as the issue you're describing could be manifest through that.

export default {
  data() {
    return {
      modeProspect: C.MODE.CONSULT_PROSPECTS,
      dash: 0,
      sub: 1,
      account: 2,
      password: 3,
      invoices: 4,
      download: 5,
      notifications: 6,
      stuff: 7,
      stuff2: 8,
      stuff3: 9,

      active: 0,
      menus: [true, false, false, false, false, false, false, false, false, false],
      type: this.$store.getters.getMode,

      newNotifications: 0
    };
  },
  methods: {
    allNotifications: function() {
      this.isLoading = true;
      db.collection("history")
        .where("isRead", "==", false)
        .onSnapshot(querySnapshot => {
          this.newNotifications = 0;
          querySnapshot.forEach(() => {
            this.newNotifications++;
          });
        });
      // .catch((error) => {
      //   this.isLoading = false;
      //   this.showError = true;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    changeActive: function(clicked) {
      // console.log(clicked);

      this.$set(this.menus, this.active, false);
      this.$set(this.menus, clicked, true);
      this.active = clicked;

      // console.log(this.active);
      // console.log(this.menus);
    },
    // display different options depending on user type, display mode
    isAdmin: function() {
      return this.$store.getters.getMode == C.MODE.CONSULT_PROSPECT;
    }
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    userLabel: {
      get() {
        return this.$store.getters.getUserLabel;
      }
    }
  },
  mounted() {
    this.allNotifications();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/sidebar.scss";
</style>
