<template>
  <div v-bind:class="{ 'navbar-dash': isDash }" class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <h5 class="m-0 p-2" style="color: #A27F68">DUKE</h5>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li v-if="loggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'DashboardOwner' }" class="nav-link">
            <i class="fad fa-couch"></i> Owner's Club
          </router-link>
        </li>
        <!-- <li v-if="loggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'MyWatches' }" class="nav-link">
            <i class="fad fa-watch"></i> MyWatches
          </router-link>
        </li> -->
        <!-- <li class="nav-item ml-4">
                  <a class="nav-link" href="/contact">Feedback</a>
              </li> -->

        <!-- user account dropdown -->
        <!-- <li v-if="isLoggedIn" class="nav-item ">
          <b-nav-item-dropdown
            class="ml-4"
            id="my-nav-dropdown"
            toggle-class="nav-link-custom"
            right
            no-caret
          >
            <template #button-content>
              <i class="fa fa-user-circle"></i>
            </template>
            <b-dropdown-group id="dropdown-header-1" header="Profile">
              <b-dropdown-text class="text-center">{{
                userDoc.name
              }}</b-dropdown-text>
              <b-dropdown-text class="text-center">{{
                userDoc.email
              }}</b-dropdown-text>
            </b-dropdown-group>
            <b-dropdown-group id="dropdown-header-2" header="Status">
              <b-dropdown-item class="text-center"
                ><span class="badge badge-pill badge-success">{{
                  userDoc.status
                }}</span></b-dropdown-item
              >
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :to="{ name: 'Account' }">
              <span class="fal fa-user text-blue mr-3"></span> My Account
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#"
              ><span class="fal fa-reply text-blue mr-3"></span>
              Logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </li> -->
        <li v-if="loggedIn" class="nav-item ml-4" @click="gotoProfile">
          <a class="nav-link"><i class="fad fa-user"></i> {{ userType }}</a>
        </li>
        <li v-if="loggedIn" class="nav-item ml-4">
          <a class="nav-link" @click="logout"><i class="fad fa-sign-out-alt"></i> Logout</a>
        </li>
        <li v-if="!loggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'DashboardDukeClub' }" class="nav-link">
            <i class="fad fa-couch"></i> Duke Club
          </router-link>
        </li>
        <li v-if="!loggedIn" class="nav-item ml-4">
          <router-link :to="{ name: 'Login' }" class="nav-link">
            <i class="fad fa-sign-out"></i> Login
          </router-link>
        </li>
        <li v-if="!loggedIn" class="nav-item ml-4">
          <a href="https://talkii.app" class="nav-link">
            <i class="fad fa-browser"></i> Back to site
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const auth = firebase.auth;

export default {
  data: () => ({
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    userId: null,
  }),
  methods: {
    logout() {
      console.log("logout from " + this.$route.name);
      if (this.$route.name == "StoreWatches") {
        this.$store.commit("setNewRoute", null);
      } else {
        this.$store.commit("setNewRoute", "Login");
      }
      auth.signOut().then(() => {
        this.$store.commit("finishProcess");
      });
    },
    gotoProfile() {
      if (!this.userType)
        return;

      if (this.userType == C.TYPE_INDEX[C.TYPE.OWNER]) {
        console.log("trying to go to owner profile");
        this.$router.push({ name: 'ProfileOwner' })
      }
    }
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    isDash: {
      get() {
        return this.$store.getters.getIsDash;
      },
    },
    loggedIn: {
      get() {
        return this.$store.getters.getAuth;
      }
    },
    userType: {
      get() {
        let userType = this.$store.getters.getUserTypeString;
        return userType;
      }
    }
  },
};
</script>

<style scoped></style>
